<template>
    <div id="engin_page">
        <toolbar-component title="Flotte" />

        <div class="pa-4">
            <template v-if="!loading">
                <p class="font-color-medium font-14 mb-6">{{ $t('engin_page.manage_engins') }}</p>

                <template v-if="engins.length > 0">
                    <datatable-filter-component :headers="headers" />

                    <v-row class="mb-2 mx-0 justify-space-between">
                        <p class="font-weight-bold">{{ $t('engin_page.total_engins', [engins.length]) }}</p>
                        <div>
                            <v-btn class="square-btn-icon" color="secondary" outlined @click="downloadCsvEngin()">
                                <v-icon color="secondary">mdi-download</v-icon>
                                <p v-if="selected_engins.length > 0" class="secondary--text font-weight-bold mb-0 ml-2">
                                    {{ selected_engins.length }} {{ $t('global.selections') }}
                                </p>
                            </v-btn>

                            <v-btn
                                v-if="selected_engins.length > 0 && userHasAccess('ENG', role_edit)"
                                class="ml-2 square-btn-icon"
                                color="secondary"
                                outlined
                                @click="openDialogRemoveEngin(null)"
                            >
                                <v-icon color="secondary">mdi-trash-can</v-icon>
                            </v-btn>

                            <!--                    <v-btn class="ml-2 square-btn-icon" color="secondary" outlined
                                                       @click="dialog_import_engin_csv = true" v-if="userHasAccess('ENG', role_edit)"
                                                >
                                                    <v-icon color="secondary">mdi-export-variant</v-icon>
                                                </v-btn>-->
                        </div>
                    </v-row>

                    <v-data-table
                        v-model="selected_engins"
                        :headers="headers"
                        :items="engins"
                        :loading="loading"
                        :search="search"
                        checkbox-color="primary"
                        class="stripe-date-table select-data-table"
                        disable-sort
                        item-key="id_engin"
                        show-select
                    >
                        <template v-slot:item.referentiel="{ item }">
                            <span>{{ $t('engin_page.' + item.referentiel.key) }}</span>
                        </template>

                        <template v-slot:item.domicile="{ item }">
                            <span>
                                {{
                                    `${item.domicile.address}, ${item.domicile.postal_code} ${item.domicile.city}, ${item.domicile.country}`
                                }}
                            </span>
                        </template>

                        <template v-slot:item.date_creation="{ item }">
                            <span>{{ moment(item.date_creation).format(date_format) }}</span>
                        </template>

                        <template v-slot:item.date_edit="{ item }">
                            <span>{{ item.date_edit ? moment(item.date_edit).fromNow() : '' }}</span>
                        </template>

                        <template v-if="userHasAccess('ENG', role_edit)" v-slot:item.actions="{ item }">
                            <v-icon class="mr-2" @click="openDialogEditEngin(item)">mdi-pencil</v-icon>
                            <v-icon class="mr-2" @click="openDialogRemoveEngin(item)">mdi-trash-can</v-icon>
                        </template>
                    </v-data-table>

                    <div class="bottom-hidden-div" />
                </template>

                <template v-else>
                    <empty-page-component
                        :link_img="'/images/error/empty_page.png'"
                        :main_text="$t('engin_page.no_engin_yet')"
                        :show_secondary_text="userHasAccess('ENG', role_edit)"
                    />
                </template>

                <v-btn
                    v-if="userHasAccess('ENG', role_edit)"
                    :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
                    color="secondary"
                    elevation="1"
                    fab
                    @click="openDialogEditEngin(null)"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </template>

            <template v-else>
                <v-skeleton-loader type="table-thead, table-tbody" />
            </template>

            <v-dialog v-model="dialog_remove_engin" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-uppercase font-weight-bold error--text">
                            {{ this.selected_engins.length > 1 ? $t('engin_page.delete_engins') : $t('engin_page.delete_engin') }}
                        </span>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <div>
                            <span v-if="this.selected_engins.length > 1">
                                {{ $t('engin_page.delete_following_engins') }}
                                <br />
                                <span
                                    v-for="selected_engin in selected_engins"
                                    :key="selected_engin.id_engin"
                                    class="font-weight-bold ml-2"
                                >
                                    - {{ selected_engin.immatriculation }}<br />
                                </span>
                            </span>

                            <span v-else>
                                {{ $t('engin_page.delete_following_engin') }}
                                <span class="font-weight-bold"> {{ selected_engin ? selected_engin.immatriculation : '' }} </span>
                            </span>
                        </div>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog_remove_engin = false">
                            <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>
                        <v-btn :loading="loading_edit" class="btn-error-loader" color="error" text @click="removeEngin()">
                            <span class="error--text font-weight-bold">{{ $t('global.validate') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_edit_engin" max-width="500px">
                <v-card>
                    <v-card-title>
                        <p class="text-uppercase font-weight-bold">{{ $t('engin_page.configure_engin') }}</p>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-form ref="formEditEngin">
                            <v-text-field
                                v-model="edited_engin.immatriculation"
                                :label="$t('engin_page.immatriculation')"
                                :rules="[rules.required, rules.immatriculation]"
                                filled
                            />

                            <v-select
                                v-model="edited_engin.id_referentiel"
                                :items="vehicle_types"
                                :label="$t('engin_page.vehicle_type')"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :rules="[rules.required]"
                                filled
                                item-value="id_referential"
                            >
                                <template slot="selection" slot-scope="data">
                                    {{ $t('engin_page.' + data.item.value) }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ $t('engin_page.' + data.item.value) }}
                                </template>
                            </v-select>

                            <v-text-field v-model="edited_engin.brand" :label="$t('engin_page.brand')" :rules="[rules.required]" filled />

                            <v-text-field v-model="edited_engin.model" :label="$t('engin_page.model')" :rules="[rules.required]" filled />

                            <v-select
                                v-model="edited_engin.id_asset_liquid"
                                :items="asset_liquids"
                                :label="$t('engin_page.liquid')"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :rules="[rules.required]"
                                filled
                                item-text="name"
                                item-value="id_asset_liquid"
                            />

                            <v-select
                                v-model="edited_engin.id_domicile"
                                :items="
                                    $store.getters['domiciles/domiciles'].filter(
                                        (domicile) => domicile.id_client === $store.getters['user_connected/user_connected'].id_client
                                    )
                                "
                                :label="$t('engin_page.domicile')"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :rules="[rules.required]"
                                filled
                                item-text="name"
                                item-value="id_domicile"
                            >
                                <template v-slot:item="{ item }">
                                    <span :class="edited_engin.id_domicile === item ? 'primary--text font-14' : 'font-14'">
                                        {{ item.address + ', ' + item.postal_code + ' ' + item.city + ', ' + item.country }}
                                    </span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span class="font-14">
                                        {{ item.address + ', ' + item.postal_code + ' ' + item.city + ', ' + item.country }}
                                    </span>
                                </template>
                            </v-select>
                        </v-form>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeDialogEditEngin()">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>
                        <v-btn
                            :disabled="!valid"
                            :loading="loading_edit"
                            class="btn-secondary-loader"
                            color="secondary"
                            text
                            @click="editEngin()"
                        >
                            <span :class="valid ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'">
                                {{ $t('global.validate') }}
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_import_engin_csv" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-uppercase font-weight-bold">
                            {{ $t('engin_page.import_engin') }}
                        </span>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <p class="mb-1">
                            <a class="font-14" @click="downloadTemplateCsvEngin()">
                                {{ $t('engin_page.download_csv_example') }}
                            </a>
                        </p>
                        <div class="mb-2" @dragenter="dragging = true" @dragleave="dragging = false">
                            <div class="dropzone-info-engin-csv pointer" @drag="prepareUpload">
                                <div class="pa-3 text-center my-auto">
                                    <v-icon>mdi-download</v-icon>
                                    <span :class="dragging ? 'primary--text' : ' font-color-medium'">
                                        {{ $t('global.import_csv_file') }}
                                    </span>
                                    <p class="font-color-light font-14 mb-0">
                                        {{ $t('global.drop_file_click_selection') }}
                                    </p>
                                    <input accept="text/csv" class="input-engin-csv" type="file" @change="prepareUpload" />
                                </div>
                            </div>
                        </div>

                        <div v-if="file !== null" class="mb-1 ml-2 font-14">
                            <a :href="url" target="_blank">{{ file.name }}</a>
                            <v-icon small @click="file = null">mdi-close</v-icon>
                        </div>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog_import_engin_csv = false">
                            <span class="font-weight-bold font-color-light">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn
                            :disabled="file === null"
                            :loading="loading_edit"
                            class="btn-secondary-loader"
                            color="error"
                            text
                            @click="uploadFile()"
                        >
                            <span :class="file === null ? 'font-weight-bold font-color-light' : 'secondary--text font-weight-bold'">
                                {{ $t('global.validate') }}
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import ToolbarComponent from '../components/Global/ToolbarComponent'
import DatatableFilterComponent from '@/components/Global/DatatableFilterComponent'
import EnginRepository from '@/repositories/EnginRepository'
import ProfessionalRepository from '@/repositories/ProfessionalRepository'
import ReferentialRepository from '@/repositories/ReferentialRepository'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'

export default {
    name: 'EnginPage',
    components: { EmptyPageComponent, DatatableFilterComponent, ToolbarComponent },
    created() {
        this.getEngins()
        this.getAssetLiquids()
        this.getReferentialEngin()
    },
    data() {
        return {
            engins: [],
            vehicle_types: [],
            asset_liquids: [],
            loading: false,

            headers: [
                { text: this.$t('engin_page.immatriculation'), value: 'immatriculation', filterable: true, visible: true, },
                { text: this.$t('engin_page.brand'), value: 'brand', filterable: true, visible: true },
                { text: this.$t('engin_page.model'), value: 'model', filterable: true, visible: true },
                { text: this.$t('engin_page.vehicle_type'), value: 'referentiel', filterable: true, visible: false },
                { text: this.$t('global.address'), value: 'domicile', filterable: true, visible: false },
                { text: this.$t('engin_page.liquid'), value: 'asset_liquid.name', filterable: true, visible: false },
                { text: this.$t('global.date_creation'), value: 'date_creation', filterable: true, visible: false },
                { text: this.$t('global.last_modification'), value: 'date_edit', filterable: true, visible: false },
                { text: this.$t('global.actions'), value: 'actions', filterable: false, visible: false },
            ],
            search: '',

            immatriculation_pattern: /^[a-z,A-Z,0-9]{2}(-)[a-z, A-Z, 0-9]{3}(-)[a-z, A-Z, 0-9]{2}$|^[a-z, A-Z, 0-9]{7}$/,
            rules: {
                required: (value) => !!value || 'Champ requis',
                immatriculation: (value) => {
                    const pattern = this.immatriculation_pattern
                    return pattern.test(value) || this.$t('engin_page.immatriculation_not_valid')
                },
            },

            edited_index: null,
            selected_engin: '',
            selected_engins: [],
            selected_engins_to_delete: [],

            dialog_edit_engin: false,
            loading_edit: false,
            edited_engin: {
                id_engin: null,
                immatriculation: null,
                brand: null,
                model: null,
                id_referentiel: null,
                id_domicile: null,
                id_asset_liquid: null,
            },
            dialog_remove_engin: false,

            dialog_import_engin_csv: false,
            valid_format_picture: 'text/csv',
            dragging: false,
            url: null,
            file: null,
        }
    },
    computed: {
        valid() {
            return (
                this.immatriculation_pattern.test(this.edited_engin.immatriculation) &&
                this.edited_engin.id_referentiel !== null &&
                this.edited_engin.brand !== null &&
                this.edited_engin.model !== null &&
                this.edited_engin.id_asset_liquid !== null &&
                this.edited_engin.id_domicile !== null
            )
        },
    },
    methods: {
        getEngins() {
            this.loading = true

            EnginRepository.getEnginByIdClient(this.$store.getters['user_connected/user_connected'].id_client)
                .then((success) => {
                    this.engins = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        getAssetLiquids() {
            ProfessionalRepository.getAssetLiquidsByProfessionalAndType(
                this.$store.getters['user_connected/user_connected'].id_professional,
                'cuve'
            )
                .then((success) => {
                    this.asset_liquids = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        getReferentialEngin() {
            ReferentialRepository.getReferentialEngin()
                .then((success) => {
                    this.vehicle_types = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        openDialogEditEngin(item) {
            if (item !== null) {
                this.edited_index = this.engins.indexOf(item)
                this.edited_engin = Object.assign({}, item)
                this.edited_engin.id_asset_liquid = item.asset_liquid.id_asset_liquid
                this.edited_engin.id_domicile = item.domicile.id_domicile
                this.edited_engin.id_referentiel = item.referentiel.id_referentiel
            } else {
                //if avoid error when form is created
                if (this.$refs.formEditEngin) {
                    this.$refs.formEditEngin.reset()
                }
            }
            this.dialog_edit_engin = true
        },

        editEngin() {
            this.loading_edit = true

            if (this.edited_index !== null) {
                this.updateEngin()
            } else {
                this.addEngin()
            }
        },

        addEngin() {
            EnginRepository.addEngin(this.edited_engin)
                .then((success) => {
                    this.engins.push(success.data.data)
                    this.showSnackbar('success', 'Ajout réussi')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.closeDialogEditEngin()
                    this.loading_edit = false
                })
        },

        updateEngin() {
            EnginRepository.updateEnginById(this.edited_engin.id_engin, this.edited_engin)
                .then((success) => {
                    Object.assign(this.engins[this.edited_index], success.data.data)
                    this.edited_index = null
                    this.showSnackbar('success', this.$t('global.add_success'))
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.closeDialogEditEngin()
                    this.loading_edit = false
                })
        },

        closeDialogEditEngin() {
            this.$refs.formEditEngin.reset()
            this.dialog_edit_engin = false
        },

        openDialogRemoveEngin(item) {
            this.selected_engins_to_delete = []
            if (this.selected_engins.length === 0) {
                this.selected_engin = item
                this.selected_engins_to_delete.push(item.id_engin)
            } else {
                this.selected_engins.forEach((engin) => {
                    this.selected_engins_to_delete.push(engin.id_engin)
                })
            }

            this.dialog_remove_engin = true
        },

        removeEngin() {
            this.loading_edit = true

            EnginRepository.deleteEnginById({ id_engins: this.selected_engins_to_delete })
                .then(() => {
                    this.selected_engins_to_delete.forEach((local_engin) => {
                        this.engins.splice(
                            this.engins.findIndex((engin) => engin.id_engin === local_engin.id_engin),
                            1
                        )
                    })

                    this.selected_engins = []
                    this.showSnackbar('success', this.$t('global.delete_success'))
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.selected_engin = ''
                    this.dialog_remove_engin = false
                    this.loading_edit = false
                })
        },

        prepareUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                this.dragging = false
                return
            }

            if (this.checkFiles(files[0])) {
                this.file = files[0]
                this.url = URL.createObjectURL(files[0])
                this.dragging = false
            }
        },

        checkFiles(new_file) {
            let valid = true

            if (new_file.type === "") {
                this.showSnackbar('error', this.$t('global.file_type_not_authorize'))
                valid = false
            }

            if (!this.valid_format_picture.includes(new_file.type)) {
                this.showSnackbar('error', this.$t('global.file_type_not_authorize'))
                valid = false
            }
            return valid
        },

        uploadFile() {
            this.loading_edit = true

            let formData = new FormData()
            formData.append('files[]', this.file)

            EnginRepository.postImportEngin(formData)
                .then(() => {
                    this.showSnackbar('success', this.$t('engin_page.engin_imported'))
                    this.getEngins()
                    this.file = null
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.dialog_import_engin_csv = false
                    this.loading_edit = false
                })
        },

        downloadCsvEngin() {
            let data = this.engins
            if (this.selected_engins.length > 0) {
                data = this.selected_engins
            }

            let csvData = []
            csvData.push([
                this.$t('engin_page.immatriculation'),
                this.$t('engin_page.brand'),
                this.$t('engin_page.model'),
                this.$t('engin_page.vehicle_type'),
                this.$t('engin_page.address'),
                this.$t('engin_page.liquid'),
                this.$t('global.date_creation'),
                this.$t('global.last_modification'),
            ])
            data.forEach((engin) => {
                csvData.push([
                    engin.immatriculation,
                    engin.brand,
                    engin.model,
                    engin.referentiel.value,
                    engin.domicile.address,
                    engin.asset_liquid.name,
                    this.moment(engin.date_creation).format(this.date_format_csv),
                    engin.date_edit ? this.moment(engin.date_edit).format(this.date_format_csv) : '',
                ])
            })

            this.downloadCsvFile(csvData, 'vehicule')
        },

        checkOutOfBound(data, index, key, value) {
            let result = ''
            if (data[key].length > index) {
                result = data[key][index][value]
            }
            return result
        },

        downloadTemplateCsvEngin() {
            let data = {
                domiciles: this.$store.getters['domiciles/domiciles'].filter(
                    (domicile) => domicile.id_client === this.$store.getters['user_connected/user_connected'].id_client
                ),
                liquids: this.asset_liquids,
                vehicle_types: this.vehicle_types,
            }

            let csvData = []
            csvData.push([this.$t('engin_page.instruction_import_csv_engin')])
            csvData.push([
                this.$t('engin_page.domicile'),
                this.$t('engin_page.liquid'),
                this.$t('engin_page.vehicle_type'),
                this.$t('engin_page.immatriculation'),
                this.$t('engin_page.brand'),
                this.$t('engin_page.model'),
            ])

            for (let i = 0; i < Math.max(data.domiciles.length, data.liquids.length, data.vehicle_types.length); i++) {
                let row = [
                    this.checkOutOfBound(data, i, 'domiciles', 'address'),
                    this.checkOutOfBound(data, i, 'liquids', 'name'),
                    this.checkOutOfBound(data, i, 'vehicle_types', 'value'),
                ]
                csvData.push(row)
            }

            this.downloadCsvFile(csvData, 'vehicule_template')
        },
    },
}
</script>

<style>
.dropzone-info-engin-csv {
    border: 1px dashed #7f828a;
    border-radius: 5px 5px 0 0 !important;
    position: relative;
}

.dropzone-info-engin-csv:hover {
    border: 1px dashed var(--v-primary-base);
}

.input-engin-csv {
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>