import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IEngin } from '@/models/IEngin'

const resource = 'api/v1/engin'

export default {
    getEnginByIdClient(idClient: number): Promise<AxiosResponse<IServerResponse<IEngin[]>>> {
        return axios.get(`${resource}/client/${idClient}`)
    },

    addEngin(engin): Promise<AxiosResponse<IServerResponse<IEngin>>> {
        return axios.post(`${resource}`, engin)
    },

    postImportEngin(files): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/import`, files)
    },

    updateEnginById(idEngin: number, data): Promise<AxiosResponse<IServerResponse<IEngin>>> {
        return axios.put(`${resource}/${idEngin}`, data)
    },

    deleteEnginById(idEngins): Promise<AxiosResponse<void>> {
        return axios.delete(`${resource}`, { data: idEngins })
    },
}
