import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'

const resource = 'api/v1/referentiel'
const reference_engin = 'ref_engins'

export default {
    getReferentialEngin(): Promise<AxiosResponse<IServerResponse<[]>>> {
        return axios.get(`${resource}/${reference_engin}`)
    },
}
